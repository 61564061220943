import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    options: [
          {
            value: '韩束',
            label: '韩束',
            children: [
              { value: '墨菊', label: '墨菊' },
              { value: '巨水光', label: '巨水光' },
              { value: '红石榴', label: '红石榴' },
              { value: '聚时光', label: '聚时光' },
              { value: '红胶囊', label: '红胶囊' },
              { value: '蓝胶囊', label: '蓝胶囊' },
              { value: '紫胶囊', label: '紫胶囊' },
              { value: '粉胶囊', label: '粉胶囊' },
              { value: '银胶囊', label: '银胶囊' },
              { value: '黑金', label: '黑金' },
              { value: '弹润紧致', label: '弹润紧致' },
              { value: '臻白淡斑', label: '臻白淡斑' },
              { value: '聚时光臻奢', label: '聚时光臻奢' },
              { value: '补水控油', label: '补水控油' },
              { value: '光感莹透', label: '光感莹透' },
              { value: '绿胶囊', label: '绿胶囊' },
              { value: '聚光白', label: '聚光白' },
              { value: '周边', label: '周边' }
            ]
          },
          {
            value: '一叶子',
            label: '一叶子',
            children: [
              { value: '鲜补水', label: '鲜补水' },
              { value: '酪梨水漾', label: '酪梨水漾' },
              { value: '鸢尾控油', label: '鸢尾控油' },
              { value: '白百合提亮', label: '白百合提亮' },
              { value: '光感匀亮', label: '光感匀亮' },
              { value: '葡萄多酚', label: '葡萄多酚' },
              { value: '芍药紧致', label: '芍药紧致' },
              { value: '黑松露', label: '黑松露' },
              { value: '积雪草', label: '积雪草' },
              { value: '雪耳', label: '雪耳' },
              { value: '橄榄嫩芽', label: '橄榄嫩芽' },
              { value: '周边', label: '周边' }
            ]
          },
          {
            value: '高肌能',
            label: '高肌能',
            children: [
              { value: '墨菊', label: '墨菊' },
              { value: '聚时光', label: '聚时光' },
              { value: '巨水光', label: '巨水光' },
              { value: '聚光白', label: '聚光白' },
              { value: '舒安特护', label: '舒安特护' },
              { value: '神经酰胺', label: '神经酰胺' },
              { value: '维C光感', label: '维C光感' },
              { value: '胶原蛋白', label: '胶原蛋白' },
              { value: '雪白肌', label: '雪白肌' },
              { value: '周边', label: '周边' }
            ]
          },
          {
            value: '欧诗漫',
            label: '欧诗漫',
            children: [
              { value: '珍珠白', label: '珍珠白' },
              { value: '珍珠水活', label: '珍珠水活' },
              { value: '珍珠营养', label: '珍珠营养' },
              { value: '珍珠毛孔', label: '珍珠毛孔' },
              { value: '珍珠水氧', label: '珍珠水氧' },
              { value: '珍白因', label: '珍白因' },
              { value: '周边', label: '周边' }
            ]
          },
          {
            value: '御泥坊',
            label: '御泥坊',
            children: [
              { value: '美白嫩肤', label: '美白嫩肤' },
              { value: '光感小灯泡', label: '光感小灯泡' },
              { value: '清爽平衡', label: '清爽平衡' },
              { value: '苦橙小金瓶', label: '苦橙小金瓶' },
              { value: '玫瑰保湿', label: '玫瑰保湿' },
              { value: '晶亮红石榴', label: '晶亮红石榴' },
              { value: '周边', label: '周边' }
            ]
          }
        ],
    leimu: [
  {
    value: '套装/礼盒',
    label: '套装/礼盒',
    children: []
  },
  {
    value: '洁面乳',
    label: '洁面乳',
    children: []
  },
  {
    value: '爽肤水/化妆水',
    label: '爽肤水/化妆水',
    children: []
  },
  {
    value: '乳液/面霜',
    label: '乳液/面霜',
    children: []
  },
  {
    value: '面部精华',
    label: '面部精华',
    children: []
  },
  {
    value: '面膜',
    label: '面膜',
    children: []
  },
  {
    value: '眼膜',
    label: '眼膜',
    children: []
  },
  {
    value: '眼霜/眼部精华',
    label: '眼霜/眼部精华',
    children: []
  },
  {
    value: '卸妆',
    label: '卸妆',
    children: []
  },
  {
    value: '其他面部产品',
    label: '其他面部产品',
    children: []
  }
        ],
    heads:[
      {
        value: '吴昭谕',
        label: '吴昭谕',
        children: []
      },
      {
        value: '戴泰熊',
        label: '戴泰熊',
        children: []
      },
      {
        value: '施佳',
        label: '施佳',
        children: []
      },
      {
        value: '谭重阳',
        label: '谭重阳',
        children: []
      },
    ],
    dianpus:[
      {
        value: '美在懂妆卖场店',
        label: '美在懂妆卖场店',
        children: []
      },
      {
        value: '乐天派美妆专营店',
        label: '乐天派美妆专营店',
        children: []
      },
      {
        value: '派美化妆品专营店',
        label: '派美化妆品专营店',
        children: []
      },
      {
        value: '韩束高肌能派美专卖店',
        label: '韩束高肌能派美专卖店',
        children: []
      },
    ]
      },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
