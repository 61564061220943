<template>
  <el-container style="border: 1px solid #eee">
    <el-aside width="200px" style="background-color: rgb(238, 241, 246);position: fixed;height: 100%;">
      <el-menu :default-openeds="['1']" :router="true">
        <el-submenu index="1">
          <template slot="title"><i class="el-icon-message"></i>导航一</template>
          <el-menu-item-group>
            <el-menu-item index="/product_list">商品列表</el-menu-item>
            <el-menu-item index="/jinpin">竞争列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header id="head" style="z-index:2;text-align: right; font-size: 12px;background-color:#B3C0D1;position: fixed;height: 60px;left: 200px;">
        <el-dropdown>
          <i class="el-icon-setting" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>查看</el-dropdown-item>
            <el-dropdown-item>新增</el-dropdown-item>
            <el-dropdown-item>删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>王小虎</span>
      </el-header>

      <el-main id="main" style="margin-left: 200px;margin-top: 60px;z-index: 0;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<style>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
</style>

<script>
  export default {

    methods:{
        // 布局
      
    },
    mounted(){
      this.$nextTick(()=>{
        var x = Number(window.innerWidth - 200)
        document.getElementById("head").style.width= x+"px"
        window.onresize=()=>{
          var windowwidth = window.innerWidth
          // console.log(windowwidth);
          var x = Number(window.innerWidth - 200)
          document.getElementById("head").style.width= x+"px"
        }
      })
    }
  }
  
</script>