import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path:'/product_list',
        component:()=>import('../views/model/productlist.vue')
      },
      {
        path:'/jinpin',
        component:()=>import('../views/model/Jinpin.vue')
      }
    ]
  },
]
const router = new VueRouter({
  routes
})

export default router
